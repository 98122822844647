<template>
  <div class="filter">
    <div class="search-header">
      <div>
        <i @click="$router.back(-1)"
           class="iconfont icon-arrow02"></i>
      </div>
      <div>
        <van-search @click="showSearch = true"
                    placeholder="搜教室、楼层" />
      </div>
    </div>
    <div class="switch-info">
      <div @click="showVagueSearch = true">
        <i class="iconfont icon-fangjian_flour"></i>
        <span>全部房间</span>
        <i class="iconfont icon-fangjian_arrow"></i>
      </div>
    </div>
    <div class="swiper-toggle-page">
      <swiper ref="mySwiper"
              :options="swiperOptions"
              :auto-destroy="true"
              :delete-instance-on-destroy="true"
              :cleanup-styles-on-destroy="true"
              @ready="handleSwiperReadied"
              @click-slide="handleClickSlide"
              :autoUpdate="true">
        <swiper-slide v-for="(item, i) in dataArr"
                      :key="i">
          <div class="toggle-equipment"
               :class="currentIndex === i ? 'active' : ''"
               @click="toggleEquipment(i,item.id,0)">
            <i class="iconfont"
               :class="'icon-fangjian_computer'"></i>
            <span>{{ item.title }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="list">
      <van-grid :column-num="2"
                :gutter="16">
        <van-grid-item v-for="(item, index) in list"
                       :key="index">
          <div class="list-item">
            <div class="l-sign">
              <i class="iconfont"
                 :class="{
                  'icon-control_deng': $route.query.equipment == 0,
                  'icon-control04_kongtiao': $route.query.equipment == 1,
                  'icon-control04_chuanglian': $route.query.equipment == 2,
                  'icon-control04_door1': $route.query.equipment == 3,
                  'icon-control04_jianceyi': $route.query.equipment == 4,
                  'icon-control02_yinliang': $route.query.equipment == 5,
                }"></i>
            </div>
            <div @click="toRoomDetail(item)"
                 class="l-title">
              {{ item.RoomNmae }}
            </div>
            <div @click="toRoomDetail(item)"
                 class="l-subtitle">
              {{ item.RoomNo }}
            </div>
            <div class="l-count">
              <div>
                <div v-if="item.checked"
                     class="count">
                  <span>{{item.oNnum}}</span>
                  <span>/{{item.sumNum}}</span>
                </div>
                <div v-if="!item.checked"
                     class="close">OFF</div>
              </div>
              <div>
                <van-switch :active-color="defineStyle.themeColor"
                            :inactive-color="defineStyle.color.gray"
                            v-model="item.checked"
                            @change="roomEqSwitch(index,item)"
                            size="24px" />
              </div>
            </div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
    <van-popup v-model="showSearch"
               position="bottom"
               style="height: 100%">
      <search-block :loading="loading"
                    :finished="finished"
                    :placeholder="placeholder"
                    @CancelSearch="showSearch = false"
                    @SearchLoad="onLoad"
                    @OnSearch="onSearch"
                    :dataList="dataList"
                    @clickSingle="setData"></search-block>
    </van-popup>

    <van-popup v-if="showVagueSearch"
               round
               v-model="showVagueSearch"
               position="bottom"
               style="height: 70%">
      <div v-if="showVagueSearch"
           class="v-search-header">
        <span>请选择楼宇楼层</span>
        <span @click="showVagueSearch = false">
          <van-icon name="cross" />
        </span>
      </div>
      <div>
        <van-tabs v-model="active"
                  animated>
          <van-tab v-for="(item, index) in tabTitle"
                   :title="item"
                   :key="index">
            <div class="popup-list">
              <div :class="child.active ? 'active' : ''"
                   @click="toggleContent(child, index,i)"
                   v-for="(child, i) in tabContent[index]"
                   :key="i">
                <van-icon name="success" />
                <span>{{ child.title }}</span>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getGardenAreaFloorRoomList, GetTerminalFloor, GetTerminalAreaType } from "@/api/TerminalEquipment/Terminal"
import { getRoomTypeEquipment } from "@/api/TerminalEquipment/RoomEquipment"
import SearchBlock from "@/components/SearchBlock.vue";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import defineMethods from "@/methods";
import {
  Search,
  Grid,
  GridItem,
  Popup,
  Switch,
  Tab,
  Tabs,
  List,
  Cell,
} from "vant";
import { Thumbs } from 'swiper';
export default {
  components: {
    SearchBlock,
    Swiper,
    SwiperSlide,
    [Search.name]: Search,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Popup.name]: Popup,
    [Switch.name]: Switch,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [Cell.name]: Cell,
  },
  data () {
    return {
      active: 0,
      placeholder: "搜教室、名称、楼层",
      searchVal: "",
      checked: false,

      dataList: [],
      tabTitle: ["请选择"],
      tabContent: [[], []],
      loading: false,
      finished: false,
      showSearch: false,
      showVagueSearch: false,
      chooseIndex: 0,
      currentIndex: 0,
      swiperOptions: {
        slidesPerView: 3.1,
        slidesPerGroup: 3,
        spaceBetween: 0,
        observer: true,
      },

      dataArr: [],
      list: [],
      roomEquipmentInfo: null
    };
  },
  directives: {
    swiper: directive,
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper;
    },
  },
  watch: {
    tabContent: {
      handler (value) {
        this.$nextTick(() => {
        });
      },
      deep: true
    }

  },
  methods: {
    setData (data) {
      let models = {
        GardenId: 14,
        AreaId: -1,
        FloorId: -1,
        RoomId: -1,
        IsDelete: 0,
        status: false,
        FloorRoomName: null,
        TerminaType: -1
      }
      if (data.mark == 1) {
        models.FloorId = data.id;
      } else {
        models.RoomId = data.id;
      }
      this.GetQueryGardenFLoorAreaRoom(models);
      this.showSearch = false;
    },
    onLoad () {
    },
    //跳转到房间
    toRoomDetail (info) {
      localStorage.RoomInfo = JSON.stringify(info);
      this.$router.push({
        path: '/SmartClassroom/Equipement',
        query: {
          active: this.$route.query.equipment,
          terType: info.TerminalType
        },
      });
    },
    async onSearch (value) {
      let models = {
        GardenId: 14,
        AreaId: -1,
        FloorId: -1,
        RoomId: -1,
        IsDelete: 0,
        status: false,
        FloorRoomName: value,
        TerminaType: -1
      }
      let Result = await getGardenAreaFloorRoomList(models)
      if (Result.ResultCode == 200) {
        var dataModel = [];
        Result.Data.forEach(x => {
          var modelFloor = {
            name: x.FloorName,
            id: x.FloorId,
            mark: 1
          }
          var mdoelRoom = {
            name: x.RoomNmae,
            id: x.RoomId,
            mark: 2
          }
          dataModel.push(modelFloor);
          dataModel.push(mdoelRoom);
        })
        this.dataList = dataModel;
        this.$forceUpdate();
      }
      else if (Result.ResultCode == 206) {
        this.dataList = [];
        this.$forceUpdate();
      } else {
        this.dataList = [];
        this.$forceUpdate();
      }
    },
    handleSwiperReadied () { },
    handleClickSlide () { },
    async roomEqSwitch (index, items) {
      //房间设备开关
      let flag = !items.checked ? false : true;
      this.list[index].checked = flag;
      let res = await getRoomTypeEquipment(items.RoomId, items.TerminalType);
      let roomEquipmentInfo = res.Data;


      this.list[index].sumNum = roomEquipmentInfo.TerminalList.length;
      this.list[index].oNnum = roomEquipmentInfo.TerminalList.length;

      let cmdJson = null;
      let lev = 0;
      if (flag) {
        //开
        cmdJson = this.getCmdJson(roomEquipmentInfo.TerminalCmd, 2);
        lev = 100;
      } else {
        //关
        cmdJson = this.getCmdJson(roomEquipmentInfo.TerminalCmd, 1);
      }
      let tCodes = [];
      roomEquipmentInfo.TerminalList.forEach(element => {
        tCodes.push(element.TerminalCode);
      });
      if (items.TerminalType == 4) {
        let obj = {
          ServersCode: tCodes[0],
          Msg: lev > 0 ? "2" : "0",
          Command: 5
        };
        console.log(JSON.stringify(obj));
        this.global.wsSendMsg('door', JSON.stringify(obj));
      }
      else if (items.TerminalType == 7) {
        this.cl_SendCmdJson_7(roomEquipmentInfo, lev);
      }
      else
        defineMethods.openTerminalCmd(cmdJson, items.TerminalType,
          roomEquipmentInfo.TerminalList[0].GatewayCode, tCodes.toString(),
          lev, 1);

    },
    //窗帘发送设备指令
    cl_SendCmdJson_7 (roomEquipmentInfo, lev) {
      let cmdJson = this.getCmdJson(roomEquipmentInfo.TerminalCmd, 1);
      let terCmd = roomEquipmentInfo.TerminalList;
      for (let index = 0; index < terCmd.length; index++) {
        const element = JSON.parse(terCmd[index].SttributeJson);
        if (index == 0) {
          defineMethods.openTerminalCmd(
            cmdJson,
            7,
            terCmd[index].GatewayCode,
            '00124B0021269DEA',
            lev > 0 ? element.OPEN : element.COLSE
          );
        } else {
          setTimeout(() => {
            defineMethods.openTerminalCmd(
              cmdJson,
              7,
              terCmd[index].GatewayCode,
              '00124B0021269DEA',
              lev > 0 ? element.OPEN : element.COLSE
            );
          }, index * 1300);
        }

      }

    },
    //获取设备指令
    getCmdJson (list, cmdType) {
      let str = '';
      list.forEach((a) => {
        if (a.CommandType == cmdType) {
          str = a.CommandJson;
        }
      });
      return str;
    },
    toggleEquipment (i, AreaId, num) {
      this.currentIndex = i;
      document.title = this.dataArr[i].title;
      let models = {
        GardenId: 14,
        AreaId: AreaId,
        FloorId: -1,
        RoomId: -1,
        IsDelete: 0,
        status: false,
        FloorRoomName: null,
        TerminaType: parseInt(this.$route.query.equipment) + 1
      }
      this.GetQueryGardenFLoorAreaRoom(models)
      if (num === 0) {
        this.tabContent[0].forEach(x => {
          if (x.active === true) {
            x.active = false;
          }
        })
        this.tabTitle = ["请选择"]
        this.$forceUpdate();
      }
    },
    //楼层房间筛选
    toggleContent (item, index, Ivalue) {
      let models = {
        GardenId: 14,
        AreaId: -1,
        FloorId: -1,
        RoomId: -1,
        IsDelete: 0,
        status: false,
        FloorRoomName: null,
        TerminaType: -1
      }
      if (index === 0) {
        models.AreaId = item.Id
        this.toggleEquipment(Ivalue + 1, item.Id, 1)
      } else {
        models.AreaId = item.AreaId;
        models.FloorId = item.id;
        this.GetQueryGardenFLoorAreaRoom(models);
      }
      let FloorModel = {
        GardenId: 14,
        AreaId: item.Id,
        IsDelete: 0
      }
      if (index === 0) {
        this.GetQuertTerminalFloor(FloorModel);
      }
      this.tabContent[index].forEach((element) => {
        this.$set(element, "active", false);
      });
      this.$set(item, "active", true);
      this.tabTitle[index] = item.title;

      if (!(index == 1)) {
        this.tabTitle[index + 1] = "请选择";
        this.active += 1;
      }
    },
    //列表
    async GetQueryGardenFLoorAreaRoom (models) {
      if (models.TerminaType == 3) {
        models.TerminaType = "3,7";
      }
      let Result = await getGardenAreaFloorRoomList(models);
      if (Result.ResultCode == 200) {
        let resList = [];
        Result.Data.forEach(element => {
          let onNum = 0;
          if (!defineMethods.isNullOrEmpty(element.SttributeJson) && element.TerminalType != 7) {
            onNum = (element.SttributeJson.indexOf("ON") > -1 ||
              element.SttributeJson.indexOf("OPEN") > -1)
              ? 1 : 0;
          }
          let first = this.getListFirst(resList, element.RoomId);
          if (first == null) {
            element.oNnum = onNum;
            element.sumNum = 1;
            element.checked = element.oNnum > 0 ? true : false;
            resList.push(element);
          } else {
            first.oNnum = first.oNnum + onNum;
            first.sumNum++;
          }
        });
        this.list = resList;
        this.$forceUpdate();
      }
      else if (Result.ResultCode == 206) {
        this.list = [];
        this.$forceUpdate();
      } else {
        alert("API接口请求错误");
      }
    },
    getListFirst (list, roomId) {
      let info = null;
      list.forEach(element => {
        if (element.RoomId == roomId) {
          info = element;
        }
      });
      return info;
    },
    //楼层
    async GetQuertTerminalFloor (Model) {
      this.tabContent[1] = [];
      let Result = await GetTerminalFloor(Model);
      if (Result.ResultCode == 200) {
        this.tabContent[1].push(...Result.Data);
        this.$forceUpdate();
      }
      else if (Result.ResultCode === 206) {

        //  this.$delete(this.tabContent[1]) ;  
        this.tabContent[1] = [];
        this.$forceUpdate();
        // this.tabContent[1].forEach((item,index)=>{
        //      this.tabContent[1].splice(index,1);
        // })       
      } else {
        alert("API接口请求错误");
      }
    },
    //区域
    async GetQueryTerminalAreaType () {
      let Result = await GetTerminalAreaType();
      if (Result.ResultCode == 200) {
        let tabContentArr = []
        let arrArea = [{ title: "全部", AreaId: -1 }]
        Result.Data.forEach(x => {
          tabContentArr.push({ active: false, title: x.title, Id: x.id });
          arrArea.push(x);
        })
        this.dataArr = arrArea;
        this.tabContent[0] = tabContentArr;
      }
      else if (Result.ResultCode == 206) {
        this.dataArr = [];
      } else {
        alert("API接口请求错误");
      }
    },
  },
  mounted () {
    let models = {
      GardenId: 14,
      AreaId: -1,
      FloorId: -1,
      RoomId: -1,
      IsDelete: 0,
      status: false,
      FloorRoomName: null,
      TerminaType: parseInt(this.$route.query.equipment) + 1
    }
    this.GetQueryGardenFLoorAreaRoom(models);
    this.GetQueryTerminalAreaType();
  },
};
</script>
<style lang="scss" scoped>
@import '../../style/public.scss';
.filter {
	width: 100%;
	height: 100%;
	background-color: $bgColor;
	.v-search-header {
		height: 56px;
		padding: 0 24px;
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f4f4f5;
		> span:first-child {
			font-size: 17px;
			font-weight: 400;
			color: $titleColor;
		}
		> span:last-child {
			color: $gray;
			font-size: 22px;
		}
	}
	.search-header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		margin-right: 16px;
		padding-top: 10px;

		> div:first-child {
			width: 28px;
			height: 28px;
			text-align: center;
			line-height: 28px;
			margin-left: 8px;
			color: $titleColor;
			> i {
				font-size: 20px;
			}
		}
		> div:last-child {
			flex: 1;
			.van-search {
				padding: 0;
			}
		}
	}
	.list {
		box-sizing: border-box;
		padding-top: 14px;
		width: 100%;
		height: calc(100% - 150px);
		overflow-y: scroll;
		.van-grid-item__content {
			border-radius: 5px;
			box-shadow: 0px 0px 10px #ebebeb;
		}
	}
	.popup-list {
		height: 500px;
		overflow-y: scroll;
		> div {
			height: 45px;
			line-height: 45px;
			color: #555;
			padding-left: 24px;
			font-size: 13px;
			font-weight: 400;
			color: #555555;

			i {
				display: none;
				margin-right: 5px;
				color: $themeColor;
			}
		}

		> div.active {
			color: $titleColor;
			i {
				display: inline-block;
			}
		}
	}
	.list-item {
		width: 100%;
		height: 100%;
		position: relative;
		.l-sign {
			position: absolute;
			top: -16px;
			right: -10px;
			color: $themeColor;
			opacity: 0.1;
			> i {
				font-size: 60px;
			}
		}
		.l-title {
			font-size: 17px;
			font-weight: 400;
			line-height: 24px;
			color: #2a2a2a;
			margin-bottom: 6px;
		}
		.l-subtitle {
			font-size: 12px;
			font-weight: 400;
			color: #949494;
			margin-bottom: 18px;
		}
		.l-count {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			> div:first-child {
				.count {
					> span {
						display: inline-block;
						font-size: 12px;
						transform: scale(0.95);
						font-weight: 400;
						color: $titleColor;
					}
					> span:first-child {
						font-size: 18px;
						font-weight: 500;
						color: $themeColor;
						transform: unset;
					}
				}
				.close {
					font-size: 11px;
					font-weight: 500;
					color: #949494;
				}
			}
		}
	}
	.swiper-container {
		overflow: visible;
	}
	.swiper-toggle-page {
		margin-left: 16px;
	}
	.toggle-equipment.active {
		background: $themeColor;
		color: #fff;
	}
	.toggle-equipment {
		width: 107px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.5);
		border-radius: 8px;
		font-size: 14px;
		font-weight: 400;
		color: #2a2a2a;
		background: #fff;
		i {
			margin-right: 7px;
		}
	}
	.switch-info {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-top: 16px;
		margin-left: 20px;
		margin-bottom: 16px;
		> div {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			> i:first-child {
				font-size: 16px;
				color: $themeColor;
				margin-right: 5px;
			}
			span {
				font-size: 15px;
				font-weight: 400;
				color: $titleColor;
			}
			> i:last-child {
				font-size: 12px;
				display: inline-block;
				transform: scale(0.78);
				margin-left: 3px;
				align-self: flex-end;
				padding-bottom: 5px;
			}
		}
	}
}
</style>
<style lang="scss">
@import '../../style/public.scss';
.filter {
	.van-search__content {
		background-color: #fff;
		border-radius: 8px;
	}
	.van-popup {
		overflow: hidden;
	}
	.van-cell,
	.van-cell__value--alone {
		color: $gray;
	}
	.van-tabs {
		height: 100%;
		.van-tabs__nav {
			background-color: transparent;
		}
		.van-tabs__wrap {
			height: 34px;
			.van-tab {
				border-radius: 10px 10px 0px 0px;
				flex: inherit;
				padding: 0 20px;
			}
		}
		.van-tabs__content {
			height: calc(100% - 34px);
			._container,
			.scroller {
				height: 100%;
			}
		}
		.van-tabs__line {
			background: $themeColor;
		}
	}
}
</style>